import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import Layout from "../layouts/index";

import Img from "gatsby-image";

const About = ({ data: { about, site } }) => (
  <Layout site={site} title="About">
    <article className="sheet">
      <h1>{about.title}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: about.descriptionNode.childMarkdownRemark.html,
        }}
      />
      <Img fluid={about.image.fluid} />
    </article>
  </Layout>
);

export default About;

export const query = graphql`
  query AboutQuery {
    about: datoCmsAbout {
      title
      image {
        fluid(maxWidth: 600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
    site {
      siteMetadata {
        siteName
      }
    }
  }
`;
